import React from 'react';
import '../../styles/whats-app.css' 
import { FaWhatsapp } from "react-icons/fa";

const Whatsapp = () => {
  return (
    <div className='whats-app-icon-container'>
        <div className='whats-app-content'>
            <a href='https://wa.me/message/HJTNRWVHI5D2A1'>
            <FaWhatsapp size={28} color='white' className='whats-app-icon' />
           </a>
        </div>
    </div>
  )
}

export default Whatsapp