import React from 'react';
import '../styles/about.css';
import AboutLanding from '../components/about/about_landing';
import Header from '../components/common/header';
import Footer from '../components/common/footer';
import Whatsapp from "../components/common/Whatsapp";

const About = () => {
  return (
    <section>
      <Whatsapp/>
        <Header />
        <AboutLanding />
        <Footer/>

    </section>
  )
}

export default About