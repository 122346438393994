import React from "react";
import { useState } from "react";
import { motion } from "framer-motion";
import {useNavigate} from 'react-router-dom';


// Images
import rush from "../../assets/home/Cars/rush.webp";
import expander from "../../assets/home/Cars/expander.webp";
import mers500 from "../../assets/home/LuxuryCategory/mrs500.webp";
import masmc20 from "../../assets/home/LuxuryCategory/maserati-mc20-2024-for-rent-dubai-8.webp";
import rrv from "../../assets/home/LuxuryCategory/Range-Rover-Vogue-2023.webp";
import g63 from "../../assets/home/LuxuryCategory/mercedes-g63.webp";
import merbamg from "../../assets/home/LuxuryCategory/mercedes-benz-e-class-amg.webp";
import bcgt2021 from "../../assets/home/LuxuryCategory/Bentley-Continental-GT-2021.webp";
import bfsw12 from "../../assets/home/LuxuryCategory/Bentley-Flying-Spur-W12.webp";
import mbg63 from "../../assets/home/LuxuryCategory/Mercedes-Benz-G63-AMG-2022.webp";
import mms680 from "../../assets/home/LuxuryCategory/Mercedes-Maybach-S680.webp";
import rrg2022 from "../../assets/home/LuxuryCategory/Rolls-Royce-Ghost-2022-Black-Badge_1.webp";
import rrvp530 from "../../assets/home/LuxuryCategory/range-rover-vogue-p530-2024.webp";

// Sports Car Images
import la from "../../assets/home/SportsCategory/2021-Lamborghini-Aventador.webp";
import cc from "../../assets/home/SportsCategory/Chevrolet-Corvette.webp";
import ff from "../../assets/home/SportsCategory/ferrari-portofino.webp";
import fme from "../../assets/home/SportsCategory/Ford-Mustang-Ecoboost-2-1.webp";
import lh2 from "../../assets/home/SportsCategory/Lamborghini-Huracan-2022.webp";
import lu from "../../assets/home/SportsCategory/Lamborghini-Urus.webp";
import mm from "../../assets/home/SportsCategory/maserati-mc20-2024.webp";
import mbg from "../../assets/home/SportsCategory/Mercedes-Benz-GTR-2019.webp";
import p911 from "../../assets/home/SportsCategory/Porsche 911Turbo S 2021 For Rent in Dubai.webp";
import pc4 from "../../assets/home/SportsCategory/Porsche-Carrera-4-GTS-2021.webp";
import pgt3 from "../../assets/home/SportsCategory/porsche-gt3-2024.webp";
import rar from "../../assets/home/SportsCategory/Rent-Audi-RS5.webp";
import BookingFormCat from "./BookingFormCat";

//advertiestments
import adv1 from "../../assets/home/Advertiestments/adv1.gif"

const Cars = () => {
  const [carChange, setCarChange] = useState("Luxury");
  const [isOpen, setIsOpen] = useState(false);
  const [car, setCar] = useState("Default car");
  const navigate = useNavigate();

  return (
    <section>
      <div className="explore__cars__header">
        <h3>Explore Our SUVs</h3>
      </div>
      <div className="explore__car__change">
        <button
          onClick={() => {
            setCarChange("Economy");
          }}
          className={`${carChange === "Economy"
              ? "selected__car__change"
              : "not__selected__car__change"
            }`}
        >
          Economy
        </button>
        <button
          onClick={() => {
            setCarChange("Luxury");
          }}
          className={`${carChange === "Luxury"
              ? "selected__car__change"
              : "not__selected__car__change"
            }`}
        >
          Luxury
        </button>
        <button
          onClick={() => {
            setCarChange("Sports");
          }}
          className={`${carChange === "Sports"
              ? "selected__car__change"
              : "not__selected__car__change"
            }`}
        >
          Sports
        </button>
      </div>

      <div
        className={`${carChange === "Economy"
            ? "wrapper__grid container cars__main__container"
            : "explore__car__change__none"
          }`}
      >
        {/* <div className=" cars__row"> */}
       
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.25 }}
          className="column individual__car"
        >
          <img src={rush} alt="" />
          <h4 className=""> Rush EX</h4>
          <p className="">Toyota</p>
          <div className="individual__car__price">
            <h5>3250AED/mo.</h5>
          </div>
          <button
            onClick={() => {
              setIsOpen(true);
              setCar("Toyota Rush EX");
            }}
          >
            Book Now
          </button>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.25, delay: 0.1 }}
          className="column individual__car"
        >
          <img src={expander} alt="" />
          <h4 className=""> Expander Highline 2024</h4>
          <p className="">Mitsubishi</p>
          <div className="individual__car__price">
            <h5>3250AED/mo.</h5>
          </div>
          <button
            onClick={() => {
              setIsOpen(true);
              setCar("Expander Highline 2024");
            }}
          >
            Book Now
          </button>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.25, delay:0.2 }}
          className="column individual__car adv-service-container"
        >
          <img src={adv1} alt="" />
          <button
            onClick={() => {
            navigate('/contact')
            }}
          >
            Enquire Now
          </button>
        </motion.div>
      </div>

      {/* Luxury Category */}

      <div
        className={`${carChange === "Luxury"
            ? "wrapper__grid container cars__main__container"
            : "explore__car__change__none"
          }`}
      >
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.4 }}
          className="column individual__car"
        >
          <img src={mers500} alt="" />
          <h4 className=""> Mercedes Benz S500 2024</h4>
          <p className=""> Mercedes </p>
          <h5>1800AED/day</h5>
          <button
            onClick={() => {
              setIsOpen(true);
              setCar("Mercedes Benz S500 2024");
            }}
          >
            Book Now
          </button>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.4, delay: 0.1 }}
          className="column individual__car"
        >
          <img src={masmc20} alt="" />
          <h4 className=""> Maserati MC20 2023</h4>
          <p className=""> Maserati</p>
          <h5>3300AED/day</h5>
          <button
            onClick={() => {
              setIsOpen(true);
              setCar("Maserati MC20 2023");
            }}
          >
            Book Now
          </button>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.4, delay: 0.2 }}
          className="column individual__car"
        >
          <img src={rrvp530} alt="" />
          <h4 className=""> Range Rover Vogue P530 2024</h4>
          <p className=""> Range Rover</p>
          <h5>2500AED/day</h5>
          <button
            onClick={() => {
              setIsOpen(true);
              setCar("Range Rover Vogue P530 2024");
            }}
          >
            Book Now
          </button>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.4 }}
          className="column individual__car"
        >
          <img src={g63} alt="" />
          <h4 className=""> Mercedes G63 Double Night Package 2024</h4>
          <p className=""> Mercedes</p>
          <h5>2700AED/day</h5>
          <button
            onClick={() => {
              setIsOpen(true);
              setCar("Range Rover Vogue P530 2024");
            }}
          >
            Book Now
          </button>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.4, delay: 0.1 }}
          className="column individual__car"
        >
          <img src={merbamg} alt="" />
          <h4 className=""> Mercedes Benz E Class AMG 2024</h4>
          <p className=""> Mercedes</p>
          <h5>1200AED/day</h5>
          <button
            onClick={() => {
              setIsOpen(true);
              setCar("Range Rover Vogue P530 2024");
            }}
          >
            Book Now
          </button>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.4, delay: 0.2 }}
          className="column individual__car"
        >
          <img src={rrv} alt="" />
          <h4 className=""> Range Rover Vogue 2023</h4>
          <p className=""> Range Rover</p>
          <h5>2400AED/day</h5>
          <button
            onClick={() => {
              setIsOpen(true);
              setCar("Range Rover Vogue P530 2024");
            }}
          >
            Book Now
          </button>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.4 }}
          className="column individual__car"
        >
          <img src={bcgt2021} alt="" />
          <h4 className=""> Bentley Continental GT 2021</h4>
          <p className=""> Bently</p>
          <h5>2200AED/day</h5>
          <button
            onClick={() => {
              setIsOpen(true);
              setCar("Bentley Continental GT 2021");
            }}
          >
            Book Now
          </button>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.4, delay: 0.1 }}
          className="column individual__car"
        >
          <img src={mms680} alt="" />
          <h4 className=""> Mercedes Maybach S680 2022</h4>
          <p className=""> Mercedes</p>
          <h5>4500AED/day</h5>
          <button
            onClick={() => {
              setIsOpen(true);
              setCar("Mercedes Maybach S680 2022");
            }}
          >
            Book Now
          </button>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.4, delay: 0.2 }}
          className="column individual__car"
        >
          <img src={bfsw12} alt="" />
          <h4 className=""> Bentley Flying Spur W12 – Black Edition 2022</h4>
          <p className=""> Bently</p>
          <h5>3000AED/day</h5>
          <button
            onClick={() => {
              setIsOpen(true);
              setCar("Bentley Flying Spur W12 – Black Edition 2022");
            }}
          >
            Book Now
          </button>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.4 }}
          className="column individual__car"
        >
          <img src={rrg2022} alt="" />
          <h4 className="">Rolls Royce Ghost 2022 Black Badge</h4>
          <p className=""> Rolls Royce</p>
          <h5>4400AED/day</h5>
          <button
            onClick={() => {
              setIsOpen(true);
              setCar("Rolls Royce Ghost 2022 Black Badge");
            }}
          >
            Book Now
          </button>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.4, delay: 0.1 }}
          className="column individual__car"
        >
          <img src={mbg63} alt="" />
          <h4 className="">
            Mercedes AMG G63 2022 Nardo Grey Double Night Package
          </h4>
          <p className=""> Mercedes</p>
          <h5>2500AED/day</h5>
          <button
            onClick={() => {
              setIsOpen(true);
              setCar("Mercedes AMG G63 2022 Nardo Grey Double Night Package");
            }}
          >
            Book Now
          </button>
        </motion.div>
      </div>

      <div
        className={`${carChange === "Sports"
            ? "wrapper__grid container cars__main__container"
            : "explore__car__change__none"
          }`}
      >
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.4 }}
          className="column individual__car"
        >
          <img src={la} alt="" />
          <h4 className=""> Lamborghini Aventador SVJ 2020</h4>
          <p className=""> Lamborgini</p>
          <h5>10000AED/day</h5>
          <button
            onClick={() => {
              setIsOpen(true);
              setCar("Lamborghini Aventador SVJ 2020");
            }}
          >
            Book Now
          </button>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.4, delay: 0.1 }}
          className="column individual__car"
        >
          <img src={cc} alt="" />
          <h4 className=""> Chevrolet Corvette Stingray 2023</h4>
          <p className=""> Chervrolet</p>
          <h5>1200AED/day</h5>
          <button
            onClick={() => {
              setIsOpen(true);
              setCar("Chevrolet Corvette Stingray 2023");
            }}
          >
            Book Now
          </button>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.4, delay: 0.2 }}
          className="column individual__car"
        >
          <img src={ff} alt="" />
          <h4 className=""> Ferrari Portofino M 2023</h4>
          <p className=""> Ferrari</p>
          <h5>2700AED/day</h5>
          <button
            onClick={() => {
              setIsOpen(true);
              setCar("Ferrari Portofino M 2023");
            }}
          >
            Book Now
          </button>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.4 }}
          className="column individual__car"
        >
          <img src={fme} alt="" />
          <h4 className=""> Ford Mustang 2021</h4>
          <p className=""> Ford</p>
          <h5>700AED/day</h5>
          <button
            onClick={() => {
              setIsOpen(true);
              setCar("Ford Mustang 2021");
            }}
          >
            Book Now
          </button>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.4, delay: 0.1 }}
          className="column individual__car"
        >
          <img src={lh2} alt="" />
          <h4 className=""> Lamborghini Huracan</h4>
          <p className=""> Lamborgini</p>
          <h5>2500AED/day</h5>
          <button
            onClick={() => {
              setIsOpen(true);
              setCar("Lamborghini Huracan");
            }}
          >
            Book Now
          </button>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.4, delay: 0.2 }}
          className="column individual__car"
        >
          <img src={lu} alt="" />
          <h4 className="">Lamborghini URUS 2022</h4>
          <p className=""> Lamborgini</p>
          <h5>2500AED/day</h5>
          <button
            onClick={() => {
              setIsOpen(true);
              setCar("Lamborghini URUS 2022");
            }}
          >
            Book Now
          </button>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.4 }}
          className="column individual__car"
        >
          <img src={mm} alt="" />
          <h4 className=""> Maserati MC20 2023</h4>
          <p className=""> Maserati</p>
          <h5>3300AED/day</h5>
          <button
            onClick={() => {
              setIsOpen(true);
              setCar("Maserati MC20 2023");
            }}
          >
            Book Now
          </button>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.4, delay: 0.1 }}
          className="column individual__car"
        >
          <img src={mbg} alt="" />
          <h4 className=""> Mercedes Benz AMG GTR</h4>
          <p className=""> Mercedes</p>
          <h5>2700AED/day</h5>
          <button
            onClick={() => {
              setIsOpen(true);
              setCar("Mercedes Benz AMG GTR");
            }}
          >
            Book Now
          </button>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.4, delay: 0.2 }}
          className="column individual__car"
        >
          <img src={p911} alt="" />
          <h4 className=""> Porsche 911 Turbo S 2021</h4>
          <p className=""> Porsche</p>
          <h5>2500AED/day</h5>
          <button
            onClick={() => {
              setIsOpen(true);
              setCar("Porsche 911 Turbo S 2021");
            }}
          >
            Book Now
          </button>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.4 }}
          className="column individual__car"
        >
          <img src={pc4} alt="" />
          <h4 className=""> Porsche Carrera 4 GTS 2021</h4>
          <p className=""> Prosche</p>
          <h5>1500AED/day</h5>
          <button
            onClick={() => {
              setIsOpen(true);
              setCar("Porsche Carrera 4 GTS 2021");
            }}
          >
            Book Now
          </button>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.4, delay: 0.1 }}
          className="column individual__car"
        >
          <img src={pgt3} alt="" />
          <h4 className=""> Porsche GT3 2024</h4>
          <p className=""> Porsche</p>
          <h5>4500AED/day</h5>
          <button
            onClick={() => {
              setIsOpen(true);
              setCar("Porsche GT3 2024");
            }}
          >
            Book Now
          </button>
        </motion.div>

        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.4, delay: 0.2 }}
          className="column individual__car"
        >
          <img src={rar} alt="" />
          <h4 className=""> Audi RS5 Sportback 2022</h4>
          <p className=""> Audi</p>
          <h5>1400AED/day</h5>
          <button
            onClick={() => {
              setIsOpen(true);
              setCar("Audi RS5 Sportback 2022");
            }}
          >
            Book Now
          </button>
        </motion.div>
      </div>
      <BookingFormCat open={isOpen} close={() => setIsOpen(false)} car={car} />
    </section>
  );
};

export default Cars;