import React from "react";
import Header from "../components/common/header";
import "../styles/home.css";
import Hero from "../components/home/hero";
import OurServices from "../components/home/ourServices";
import Cars from "../components/home/cars";
import Footer from "../components/common/footer";
import WhyChoose from "../components/home/whychoose";
import Whatsapp from "../components/common/Whatsapp";

const Home = () => {
  
  return (
    <div>
        <>
          <Whatsapp />
          <Header />
          <Hero />
          <Cars />
          <WhyChoose className="margin-for-launch" />
          <OurServices />
          <Footer />
        </>
    </div>
  );
};

export default Home;
